import request from "@/utils/request";

export function getJumpUrl() {
  let url = "https://zhaopin.moguding.net";
  switch (process.env.NODE_ENV) {
      case "development":
          url = "http://42.96.173.84:10005";
          break;
  }
  return url;
}

export function getServerUrl() {
  let url = "https://apizp.moguding.net";
  switch (process.env.NODE_ENV) {
      case "development":
          url = "http://42.96.173.84:9000";
          break;
  }
  return url;
}

// (HTTP为了避免歧义，一些字符在url中会丢失)
export function encryptCode(data) {
  return encodeURIComponent(window.btoa(
      unescape(encodeURIComponent(JSON.stringify(data)))
  ));
}

// 获取验证码判断是否注册过 (1.0.1)
export function getUserSmsCode(data) {
    return request({
        url: getServerUrl() + "/session/user/v2/smsCode",
        method: "post",
        data,
    });
}

// 微信公众号二维码
export function getWxCode(data) { 
    return request({
        url: getServerUrl() + "/session/wechat/getOfficialQrCode",
        method: "post",
        data,
    });
}

// 采用手机号 验证码形式登录
export function loginUserSms(data) {
    return request({
        url: getServerUrl() + "/usercenter/sign/v2/sms/registerOrLogin",
        method: "post",
        data,
    });
}

//手机号注册
export function userRegCodeLogin(data) {
    return request({
        url: getServerUrl() + "/usercenter/sign/v1/sms/register",
        method: "post",
        data,
    });
}
