<template>
  <div class="footer">
    <div class="footer">
        <div class="_w">
          <div class="top">
            <div class="about">
              <p>关于我们</p>
              <p>掌淘网络科技（上海）有限公司</p>
              <p>全国职业教育实践育人产教联盟理事单位</p>
              <p>电 话：400-888-3518</p>
              <p>邮 箱：mnotify@zhangtaokeji.com</p>
              <p>地 址：中国（上海）自由贸易试验区芳春路400号1幢3层</p>
            </div>
            <div class="qrcode">
              <div class="wechat">
                <img src="@/assets/home_img/mgdWechat.jpg" alt="" />
                <p>微信公众号</p>
              </div>
              <div class="wechat">
                <img src="@/assets/home_img/company_contact.jpeg" alt="" />
                <p>扫码找工作</p>
              </div>
            </div>
          </div>
          <div class="record">
            <p>
              <span
                >Copyright©2012-2024 掌淘网络科技（上海）有限公司 AII Right Reserved
                沪ICP备15057510号-3</span
              >
              <i class="police"></i>
              <span>沪公网安备 31011502014769号</span>
            </p>
            <p>企业服务热线和举报投诉 400 888 3518（8：30 - 18：00）</p>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'FooterView'
}
</script>

<style lang='less' scoped>
* {
  padding: 0;
}
.footer {
  width: 100%;
  min-height: 32.75rem;
  background: #272625;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px 0;
  box-sizing: border-box;
  height: auto;
}
.footer .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .top .about {
  color: #ffffff;
  font-size: 0.875rem;
}
.footer .top .about p:nth-child(1) {
  font-size: 1rem;
}
.footer .top .about p {
  margin-bottom: 1rem;
}
.footer .top .qrcode {
  width: 22.5rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.footer .top .qrcode .wechat {
  width: 6.125rem;
  height: 10rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.footer .top .qrcode .wechat img {
  width: 6.125rem;
  height: 6.125rem;
  border-radius: 0.25rem;
}
.footer .top .qrcode .wechat p {
  font-size: 0.875;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
  line-height: 3rem;
}
.footer .top .qrcode .Discount {
  width: 11rem;
  height: 10rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.footer .top .qrcode .Discount img {
  width: 6.125rem;
  height: 6.125rem;
  border-radius: 0.25rem;
}
.footer .top .qrcode .Discount p {
  font-size: 0.875;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
  line-height: 3rem;
}
.footer .record {
  margin-top: 4rem;
  text-align: center;
  vertical-align: middle;
}
.footer .record p {
  font-size: 0.75rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
  margin: 0.75rem 0 0.75rem 0;
}
.footer .record .police {
  display: inline-block;
  width: 1.125rem;
  height: 1.1875rem;
  margin: 0.75rem 0.75rem -0.25rem 3rem;
  background: url("@/assets/home_img/police.png") no-repeat;
  background-size: cover;
}
</style>