<template>
  <div class="company">
    <div class="_w">
      <div class="up">
        <div class="left"></div>
        <div class="title">优质企业信赖伙伴</div>
        <div class="right"></div>
      </div>
    </div>
    <div class="companyIco">
      <el-row :gutter="0">
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/huawei.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/jingdongwuliu.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/bishengke.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/dikalong.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/haixin.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/huarun.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/beidou.png" alt="" />
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/kewosi.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/quchenshi.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/jiliqiche.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/wangwang.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/zhongguoshiyou.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/kongke.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/furijituan.png" alt="" />
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/manner.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/kfc.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/woerma.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/zhongtie.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/xingbake.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/youdaguangdian.png" alt="" />
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <img src="@/assets/home_img/haiwangxingchen.png" alt="" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinCompany",
};
</script>

<style lang="less" scoped>
._w {
  width: 63%;
  //   border: 1px solid red;
}
.company {
  width: 100%;
  height: 43.75rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: center;
}

.company .up {
  height: 2.75rem;
  display: flex;
  align-items: center;
  margin-top: 3rem;
  //   justify-content: space-between;
  //   flex-direction: column;
  //   border: 1px solid red;
}

.company .up .left {
  width: 24.125rem;
  height: 2px;
  background: url("@/assets/home_img/copy9.png") no-repeat;
  background-size: cover;
}

.company .up .right {
  width: 24.125rem;
  height: 2px;
  background: url("@/assets/home_img/copy8.png") no-repeat;
  background-size: cover;
}

.company .up .title {
  width: 22.25rem;
  font-size: 2.75rem;
  font-family: OPPOSans;
  font-weight: bold;
  color: #333333;
  text-align: center;
  flex: 1 1 auto;
  white-space: nowrap;
  //   margin: 0 2rem 0 2rem;
}
  
.company .companyIco {
  margin-top: 3.75rem;
  .el-row:nth-child(2) {
    margin: 2.375rem 0 2.375rem 0;
  }
  ::v-deep .el-col-6 {
    width: 14.75rem;
    height: 7.5rem;
    vertical-align: middle;
    img {
      width: 100%;
      height: 7.5625rem;
      object-fit: cover;
    }
  }
}
</style>
