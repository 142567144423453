<template>
  <div>
    <div class="always" v-show="always">
      <p><span style="color: #fe5e10"></span> 专属服务官，助你获得更多投递！</p>
      <button @click="centerDialogVisible = true">立刻联系</button>
      <p class="close" @click="close">X</p>
    </div>

    <el-dialog :visible.sync="centerDialogVisible" width="400px" center>
      <div class="dialog">
        <div>
          <p class="title">蘑菇丁企业版</p>
          <p class="subTitle">找技能人才，就上蘑菇丁</p>
          <img
            src="@/assets/home_img/company_contact.jpeg"
            alt=""
            style="width: 200px; height: 200px"
          />
          <p class="text">扫码立即联系专属服务官</p>
        </div>
        <div class="bottom">
          <img src="@/assets/home_img/logo.png" alt="" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "contact-dialog",
  data() {
    return {
      always: true,
      centerDialogVisible: false,
    };
  },
  created() {
    this.$bus.$on("contactDialog", this.changeAlways);
  },
  beforeDestroy() {
    this.$bus.$off("contactDialog", this.changeAlways);
  },
  methods: {
    close() {
      this.always = false;
    },
    changeAlways(res) {
      this.always = res;
    },
  },
};
</script>

<style lang="less" scoped>
.always {
  width: 100%;
  height: 6rem;
  background: #ffffff;
  font-size: 1.625rem;
  font-family: OPPOSans;
  font-weight: regular;
  color: #333333;
  line-height: 3rem;
  vertical-align: middle;
  display: flex;
  align-items: center;
  // flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 0;
  z-index: 4;
  box-shadow: 0rem 0rem 1.5rem 0.5rem rgba(68, 68, 68, 0.08);
}
.always button {
  width: 11.625rem;
  height: 3.125rem;
  background: #ffb20c;
  border-radius: 5px;
  border: none;
  margin: 0 1.6875rem 0 4rem;
  cursor: pointer;
}
.always p,
button {
  display: inline-block;
  font-size: 1.125rem;
  font-family: OPPOSans;
  font-weight: regular;
  color: #000000;
}
.always .close {
  color: #d1d1d1;
  font-size: 1.3125rem;
  cursor: pointer;
}

::v-deep .el-dialog--center .el-dialog__body {
  padding: 0;
  .dialog {
    // height: 500px;
    background: #ffffff;
    border-radius: 0.25rem;
    text-align: center;

    .title {
      font-size: 2rem;
      font-family: OPPOSans;
      font-weight: bold;
      color: #191919;
      margin-top: 3.5rem;
    }

    .subTitle {
      font-size: 1rem;
      font-family: OPPOSans;
      font-weight: normal;
      color: #666666;
      margin: 1rem 0 1.5rem 0;
    }

    .text {
      font-size: 1.125rem;
      font-family: OPPOSans;
      font-weight: normal;
      color: #333333;
      margin: 1.5rem 0;
    }
    .bottom {
      width: 100%;
      background: #2a2313;
      // border-radius: 0 0 4px 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
      img {
        height: 20px;
      }
    }
  }
}
</style>
