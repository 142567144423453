import { render, staticRenderFns } from "./MgdLoginV2.vue?vue&type=template&id=73966292&scoped=true"
import script from "./MgdLoginV2.vue?vue&type=script&lang=js"
export * from "./MgdLoginV2.vue?vue&type=script&lang=js"
import style0 from "./MgdLoginV2.vue?vue&type=style&index=0&id=73966292&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73966292",
  null
  
)

export default component.exports