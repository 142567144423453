import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './rem'
import axios from 'axios'
import VueLoopScroll from 'vueloopscroll'
import 'vueloopscroll/lib/vueloopscroll.css'
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

// import './styles.scss'

Vue.config.productionTip = false
Vue.use(ElementUI, VueLoopScroll)
Vue.prototype.$http = axios

new Vue({
  router,
  beforeCreate() {
    this.__proto__.$bus = this;
  },
  render: (h) => h(App)
}).$mount('#app')
