import { render, staticRenderFns } from "./JoinCompany.vue?vue&type=template&id=3390bab8&scoped=true"
import script from "./JoinCompany.vue?vue&type=script&lang=js"
export * from "./JoinCompany.vue?vue&type=script&lang=js"
import style0 from "./JoinCompany.vue?vue&type=style&index=0&id=3390bab8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3390bab8",
  null
  
)

export default component.exports