<template>
  <div class="layout">
    <div class="header">
      <div class="_w m_auto flex align-center space-between">
        <div class="flex align-center">
          <img
            class="logo"
            src="../assets/recruit_img/logo-108.png"
            alt="蘑菇丁logo"
          />
          <span class="p_0_10 title">蘑菇丁</span>
          <span class="p_0_10 grey">找技能人才 就上蘑菇丁</span>
        </div>
        <div class="head-right">
          <el-popover placement="bottom-end" trigger="hover">
            <div class="tipsTxt">
              <div class="connect-Img">
                <img src="../assets/appdownload_img/download.png" />
              </div>
              <div class="info">
                <div class="line1">蘑菇钉求职端</div>
                <div class="line2">实习就业，来蘑菇丁</div>
              </div>
            </div>
            <div class="box" slot="reference">
              <img src="../assets/home_img/scan.png" alt="扫码图标" />
              扫码下载蘑菇丁APP
            </div>
          </el-popover>
        </div>
      </div>
    </div>

    <div class="banner">
      <div class="img-box">
        <img
          src="https://qiniu.moguding.net/moguding/company/head/41ea9bd23fd18a830575847e499ecc16.png"
          alt="图像"
        />
      </div>
      <div class="login-block">
        <div class="tabs">
          <div
            class="tab"
            v-for="(tab, index) in tabs.list"
            :key="index"
            :class="{ active: index === tabs.index }"
            @click="onTabChange(index)"
          >
            {{ tab }}
          </div>
        </div>
        <div v-show="tabs.index === 0" class="content">
          <img src="../assets/home_img/mgd_wxcode.png" alt="微信二维码" />
          <div class="info">【微信】扫码打开蘑菇丁小程序</div>
        </div>
        <div
          v-show="tabs.index === 1"
          class="content"
          style="justify-content: end"
        >
          <mgd-login-v2></mgd-login-v2>
        </div>
        <!-- <mgd-login class="login-main"></mgd-login> -->
      </div>
    </div>

    <div class="quotation">
      <div class="_w">
        <div class="card">
          <img src="../assets/home_img/home-icon1.png" alt="图标" />
          <p class="text">名企大厂</p>
          <p class="subText">百万优质企业入驻</p>
        </div>
        <div class="card">
          <img src="../assets/home_img/home-icon2.png" alt="图标" />
          <p class="text">精准招聘</p>
          <p class="subText">AI推荐高效匹配</p>
        </div>
        <div class="card">
          <img src="../assets/home_img/home-icon3.png" alt="图标" />
          <p class="text">专属服务</p>
          <p class="subText">专属人才服务官极速反馈</p>
        </div>
        <div class="card">
          <img src="../assets/home_img/home-icon4.png" alt="图标" />
          <p class="text">就业实习</p>
          <p class="subText">实习就业岗位一站式解决</p>
        </div>
      </div>
    </div>

    <JoinCompany></JoinCompany>
  </div>
</template>

<script>
// import mgdLogin from "@/components/mgd-login.vue";
import JoinCompany from "./components/JoinCompany.vue";
import MgdLoginV2 from "./components/MgdLoginV2.vue";

export default {
  name: "HomeView",
  data() {
    return {
      tabs: {
        list: ["我要找工作", "我要招人"],
        index: 0,
      },
    };
  },
  components: {
    MgdLoginV2,
    JoinCompany,
  },
  created() {
    if (window.screen.width < 720 && !sessionStorage.getItem("mobile")) {
      sessionStorage.setItem("mobile", 1);
      this.$router.push("/goMiniProgram");
    }
  },
  mounted() {},
  methods: {
    onTabChange(index) {
      this.tabs.index = index;
    },
    onGoMiniProgram() {
      // 【选填】 env_version ：要打开的小程序版本,正式版为 =release, 体验版为 trial, 开发版为 develop, 仅在微信外打开时生效。注意：若不填写，则默认打开正式版小程序
      const url = `weixin://dl/business/?appid=wxb82f734ef69aef14&path=pages/work/work`;
      try {
        window.location.href = url;
      } catch (error) {
        window.open(url);
      }
    },
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
}

html {
  overflow-y: scroll;
}

._w {
  width: 63%;
}

.p_0_10 {
  padding: 0 0.625rem;
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}
.grey {
  color: #666666;
}
.font-14 {
  font-size: 0.875rem;
}
.m_auto {
  margin: 0 auto;
}

.layout {
  .header {
    padding: 1rem 0;
    font-size: 1.125rem;
    box-shadow: 0px 0px 0.3125rem 0px rgba(0, 0, 0, 0.15);
    .logo {
      width: 2.625rem;
      height: 2.625rem;
      border-radius: 0.625rem;
    }
    .title {
      font-size: 1.25rem;
      font-weight: bold;
    }
    .head-right {
      cursor: pointer;
      color: #555555;
      font-size: 0.875rem;
      .box {
        display: flex;
        align-items: center;
        gap: 0.3125rem;
        img {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
  .banner {
    background-color: #ffffff;
    // height: 42rem;
    background: url(https://qiniu.moguding.net/moguding/company/head/243e73d89778e376b5c7b37bd18412bf.png)
      no-repeat;
    background-position: -7.5rem 100%;
    background-size: 107% 107%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 3rem 0 5rem;

    .img-box {
      img {
        width: 54rem;
      }
    }
    .login-block {
      box-sizing: border-box;
      width: 34rem;
      height: 30rem;
      margin-left: -10rem;
      padding: 2.7rem 3.13rem;
      background-color: #ffffff;
      border-radius: 0.75rem;
      box-shadow: 0px 0px 1.5rem 0.5rem rgba(68, 68, 68, 0.08);
      margin-right: 4rem;
      position: relative;
      display: flex;
      flex-direction: column;
      .tabs {
        color: #777777;
        line-height: 2.63rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 1.88rem;
        padding-bottom: 1rem;
        .tab {
          cursor: pointer;
        }
        .active {
          font-weight: bold;
          color: #222222;
          line-height: 2.63rem;
          position: relative;
          &::after {
            position: absolute;
            content: "";
            left: 50%;
            bottom: -0.7rem;
            transform: translateX(-50%);
            width: 20%;
            height: 0.25rem;
            background-color: #fe8a12;
            border-radius: 0.19rem;
          }
        }
      }
      .content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        img {
          width: 15rem;
          height: 15rem;
        }
        .info {
          font-size: 1.75rem;
          font-weight: bold;
          color: #222222;
          line-height: 2.5rem;
        }
      }
    }
  }
  .quotation {
    margin: 4rem 0 5rem;
    ._w {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .card {
        display: flex;
        justify-self: center;
        align-items: center;
        flex-direction: column;
        img {
          width: 3.44rem;
          height: 3.44rem;
        }
        .text {
          font-size: 1.25rem;
          color: #666666;
          line-height: 1.75rem;
          font-weight: bold;
          padding: 1rem 0 0.25rem;
        }
        .subText {
          font-size: 0.94rem;
          color: #666666;
          line-height: 1.31rem;
          font-weight: 100;
        }
      }
    }
  }
}

.tipsTxt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;

  .info {
    line-height: 1.875rem;
    text-align: center;
    .line1 {
      color: #333333;
      font-size: 1.2rem;
      font-weight: bold;
    }
    .line2 {
      color: #666666;
      font-size: 0.875rem;
    }
  }
  .connect-Img {
    width: 6.25rem;
    height: 6.25rem;
    text-align: center;
    img {
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (min-width: 969px) and (max-width: 1800px) {
}

@media screen and (max-width: 600px) {
}
</style>
