<template>
  <el-container>
    <el-main style="padding: 0">
      <router-view />
    </el-main>
    <el-footer v-if="pageFooter">
      <FooterView></FooterView>
    </el-footer>
    <contact-dialog></contact-dialog>
  </el-container>
</template>

<script>
import FooterView from './components/FooterView.vue';
import contactDialog from './components/contactDialog.vue';

export default {
  name: "App",
  components: {
    FooterView,
    contactDialog
},
  data() {
    return {
      pageFooter: true
    };
  },
  created() {
    this.$bus.$on("changeFooter", this.changeFooter);
  },
  beforeDestroy() {
    this.$bus.$off("changeFooter", this.changeFooter);
  },
  methods: {
    changeFooter(v) {
      this.pageFooter = v;
    }
  },
};
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
* {
  padding: 0;
  margin: 0;
}
html {
  overflow-y: scroll;
}
::v-deep .el-main {
  overflow: hidden;
}
._w {
  width: 63%;
}
::v-deep .el-footer {
  padding: 0;
}
</style>
