// 协议
const Protocol = {
    PrivacyAgreement: "https://www.moguding.net/legal/html/device/privacy.html", // 隐私协议
    ServiceAgreement:
        "https://www.moguding.net/legal/html/device/customer.html ", // 服务协议
};

/**
 * 验证手机号
 */
function isPoneAvailable(poneInput) {
    const myreg = /^[1][0-9][0-9]{9}$/;
    if (!myreg.test(poneInput)) {
        return false;
    } else {
        return true;
    }
}

export default { Protocol, isPoneAvailable };
